<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            Delivery Information
            <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
            <v-form ref="formDelivery" v-model="valid" class="mb-4">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="deliveryInformation.location"
                            :rules="[rules.required]"
                            prepend-icon="mdi-map-marker"
                            label="Location *"
                            required
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            v-model="deliveryInformation.contact"
                            :filter="customFilter"
                            :hide-no-data="!search"
                            :items="this.clientContacts"
                            item-value="name"
                            item-text="name"
                            hide-details
                            prepend-icon="mdi-account"
                            :search-input.sync="search"
                            hide-selected
                            label="Contact *"
                            required
                            flat
                            @change="setPhone"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="deliveryInformation.phone"
                            :rules="[rules.required]"
                            prepend-icon="mdi-phone"
                            label="Phone *"
                            required
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-form>

            <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveInfo"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'DeliveryInfoForm',
    props: {
        packingList: {
            type: Object,
            required: true,
            default: () => {},
        },
        clientId: {
            type: String,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            editing: null,
            editingIndex: -1,
            search: null,
            valid: false,
            isEditing: false,
            rules: {
                required: v => !!v || 'Required',
            },
            clientContacts: [],
            deliveryInformation: {},
        }
    },
    async mounted() {
        try {
            if (this.packingList && this.packingList.deliveryInformation) {
                this.deliveryInformation = _.cloneDeep(
                    this.packingList.deliveryInformation
                )
            }
            this.loading = true
            const {
                data: { contacts },
            } = await API.getContactsByClient({
                clientId: this.clientId,
            })
            this.clientContacts = contacts
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        customFilter(item, queryText, itemText) {
            const hasValue = this.clientContacts.some(
                option => option.name === item
            )
            const itemNormalized = itemText.toLowerCase()
            const queryNormalized = queryText.toLowerCase()

            if (!hasValue && itemNormalized.startsWith(queryNormalized)) {
                return true
            }

            return itemNormalized.includes(queryNormalized)
        },
        setPhone(selectedContact) {
            if (selectedContact.phone) {
                this.deliveryInformation.contact = selectedContact.name
                this.deliveryInformation.phone = selectedContact.phone
            } else if (selectedContact.phone2) {
                this.deliveryInformation.contact = selectedContact.name
                this.deliveryInformation.phone = selectedContact.phone2
            } else {
                this.deliveryInformation.phone = ''
            }
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        async saveInfo() {
            try {
                this.loading = true
                if (this.packingList.id) {
                    await API.updatePackingList({
                        projectId: this.packingList.projectId,
                        packingListId: this.packingList.id,
                        deliveryInformation: this.deliveryInformation,
                    })
                }
                this.$emit('replaceDeliveryInfo', this.deliveryInformation)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
